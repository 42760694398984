import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SessionHeartbeatService {
  private heartbeatInterval = 3600 * 1000;
  private heartbeatSubscription: Subscription | null = null;
  private authService: AuthService;

  constructor(private http: HttpClient, private injector: Injector) {}

  startHeartbeat() {
    console.log('start heartbeat')
    console.log('heartbeat subscription ',this.heartbeatSubscription)
    if (!this.heartbeatSubscription) {
      console.log('brak sub heart')
      this.heartbeatSubscription = interval(this.heartbeatInterval).subscribe(() => {
        console.log('Checking session validity...');
        this.checkSession();
      });
    }
  }

  stopHeartbeat() {
    if (this.heartbeatSubscription) {
      this.heartbeatSubscription.unsubscribe();
      this.heartbeatSubscription = null;
    }
  }

  private getAuthService(): AuthService {
    console.log('this.authServic ',this.authService)
    if (!this.authService) {
      console.log('get auth')
      this.authService = this.injector.get(AuthService);
    }
    return this.authService;
  }

  private checkSession() {
    console.log('check session')
    this.http.get(`${environment.apiUri}/auth/verify-token`, { withCredentials: true }).subscribe({
      next: () => {
        console.log('Session is still valid');
      },
      error: (err) => {
        console.error('Session check failed', err);
        this.getAuthService().logout(true);
      },
    });
  }
}
