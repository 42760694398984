import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  finalize,
  map,
  Observable,
  throwError,
} from "rxjs";
import { environment } from "src/environments/environment";

export interface Campaign {
  id: number;
  name: string;
  name_workspace: string;
  owner: number;
  status: string;
  is_activated: boolean;
  public: string;
  module?: string;
  engine: number;
  type: number;
  sharing: number;
  shared: number;
  online: number;
  phonenumber_in: number;
  phonenumber_out: number;
  isFavourite: boolean;
}

@Injectable({
  providedIn: "root",
})
export class CampaignsService {
  private apiBaseUri = `${environment.apiUri}/campaigns`;
  private apiUserBaseUri = `${environment.apiUri}/user`;
  private apiBaseStatisticUri = `${environment.apiUri}`;

  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSubject.asObservable();

  private selectedCampaign = new BehaviorSubject<any>(this.getSelectedCampaignFromStorage());
  selectedCampaign$ = this.selectedCampaign.asObservable().pipe(
    distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
  );

  // Cache for campaigns
  public campaignsCache = new BehaviorSubject<Campaign[] | null>(null);

  // Observable for cached campaigns
  public campaigns$ = this.campaignsCache.asObservable();

  constructor(private http: HttpClient) {
    const savedCampaign = localStorage.getItem("selectedCampaign");
    if (savedCampaign) {
      console.log(
        "Restoring selectedCampaign from localStorage:",
        savedCampaign
      );
      this.selectedCampaign.next(JSON.parse(savedCampaign));
    }
  }

  startLoading(): void {
    this.isLoadingSubject.next(true);
  }

  stopLoading(): void {
    this.isLoadingSubject.next(false);
  }

  private isCampaignsLoading = false;

  initializeCampaigns(userId: string): Promise<void> {
    if (this.isCampaignsLoading) return Promise.resolve(); // Avoid duplicate calls

    this.isCampaignsLoading = true;
    return this.http
      .get<{ data: Campaign[] } | undefined>(
        `${this.apiBaseUri}/user/${userId}`,
        { withCredentials: true }
      )
      .toPromise()
      .then((response) => {
        if (response && response.data) {
          this.campaignsCache.next(response.data);
        } else {
          console.warn("No campaigns data found.");
          this.campaignsCache.next([]);
        }
      })
      .catch((error) => {
        console.error("Failed to load campaigns:", error);
        this.campaignsCache.next([]);
      })
      .finally(() => {
        this.isCampaignsLoading = false;
      });
  }

  // initializeCampaigns(userId: string): Promise<void> {
  //   return this.http
  //     .get<{ data: Campaign[] } | undefined>(`${this.apiBaseUri}/user/${userId}`, { withCredentials: true })
  //     .toPromise()
  //     .then((response) => {
  //       if (response && response.data) {
  //         this.campaignsCache.next(response.data); // Assign campaigns if data exists
  //       } else {
  //         console.warn("No campaigns data found.");
  //         this.campaignsCache.next([]); // Fallback to an empty array
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Failed to load campaigns:", error);
  //       this.campaignsCache.next([]); // Fallback to an empty array on error
  //     });
  // }

  getCampaignsFromCache(): Observable<Campaign[]> {
    console.log("Fetching campaigns from cache...");
    return this.campaigns$.pipe(
      distinctUntilChanged(
        (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
      ),
      map((campaigns) => {
        console.log("Cached campaigns:", campaigns);
        return campaigns || []; // Fallback to empty array
      })
    );
  }

  // getCampaignsFromCache(): Observable<Campaign[]> {
  //   console.log('Fetching campaigns from cache...');
  //   return this.campaigns$.pipe(
  //     map((campaigns) => {
  //       console.log('Cached campaigns:', campaigns); // Log the cached campaigns
  //       return campaigns || []; // Ensure an empty array is returned if campaigns is null or undefined
  //     })
  //   );
  // }

  getCampaigns(userId: string): Observable<{ data: Campaign[] }> {
    const url = `${this.apiBaseUri}/user/${userId}`;
    console.log("url ", url);
    return this.http
      .get<{ data: Campaign[] }>(url, { withCredentials: true })
      .pipe(catchError(this.handleError));
  }

  createCampaign(newCampaign: {
    user_id: number;
    workspace_id: number;
    name: string;
  }): Observable<any> {
    this.startLoading();
    return this.http
      .post<any>(this.apiBaseUri, newCampaign, { withCredentials: true })
      .pipe(
        catchError(this.handleError),
        finalize(() => this.stopLoading())
      );
  }

  updateCampaign(campaignId: number, updatedCampaign: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiBaseUri}/${campaignId}`,
      updatedCampaign,
      { withCredentials: true }
    );
  }

  deleteCampaign(campaignId: number): Observable<any> {
    return this.http
      .delete<any>(`${this.apiBaseUri}/${campaignId}`, {
        withCredentials: true,
      })
      .pipe(catchError(this.handleError));
  }

  getCampaignsByWorkspaceId(
    workspaceId: number
  ): Observable<{ data: Campaign[] }> {
    const url = `${this.apiBaseUri}/workspace/${workspaceId}`;
    return this.http
      .get<{ data: Campaign[] }>(url, { withCredentials: true })
      .pipe(catchError(this.handleError));
  }

  updateCampaignStatus(
    campaignId: number,
    updatedCampaign: any
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiBaseUri}/${campaignId}`,
      updatedCampaign,
      { withCredentials: true }
    );
  }

  private validateCampaign(campaign: any): boolean {
    return campaign && typeof campaign.public === "string";
  }

  setSelectedCampaign(campaign: any): void {
    if (campaign && campaign.public) {
        console.log('Setting selected campaign:', campaign);
        this.selectedCampaign.next(campaign);
        localStorage.setItem('selectedCampaign', JSON.stringify(campaign));
    } else {
        console.warn('Invalid campaign data:', campaign);
        this.selectedCampaign.next(null);
        localStorage.removeItem('selectedCampaign');
    }
}


  // setSelectedCampaign(campaign: any): void {
  //   if (this.validateCampaign(campaign)) {
  //     console.log('Setting campaign in service:', campaign);
  //     this.selectedCampaign.next(campaign);
  //     localStorage.setItem('selectedCampaign', JSON.stringify(campaign));
  //   } else {
  //     console.warn('Invalid campaign data. Removing from storage.');
  //     this.selectedCampaign.next(null);
  //     localStorage.removeItem('selectedCampaign');
  //   }
  // }

  getSelectedCampaignFromStorage(): any {
    const campaignData = localStorage.getItem("selectedCampaign");
    if (campaignData) {
      const parsedData = JSON.parse(campaignData);
      console.log("Restored campaign from localStorage:", parsedData);
      return parsedData;
    } else {
      console.warn("No campaign found in localStorage");
      return null;
    }
  }

  getCampaignStats(campaignPublic: string): Observable<any> {
    const url = `${environment.apiUri}/campaigns/statistics/${campaignPublic}`;
    return this.http.get<any>(url, { withCredentials: true }).pipe(
      map((response) => {
        const stats = response?.data;
        if (!stats || typeof stats !== "object") {
          throw new Error("Invalid response structure: data is not an object");
        }

        if (!stats.collection) {
          throw new Error(
            "Invalid response structure: collection is undefined"
          );
        }

        return {
          collection: stats.collection,
          created: stats.created,
          period: stats.period,
        };
      }),
      catchError((error) => {
        console.error(
          "An error occurred while fetching campaign stats:",
          error
        );
        return this.handleError(error);
      })
    );
  }

  retryContact(publicId: string): Observable<any> {
    const url = `${environment.apiUri}/campaigns/retry/${publicId}`;
    return this.http
      .post<any>(url, {}, { withCredentials: true })
      .pipe(catchError(this.handleError));
  }

  // Fetch campaign prompt data
  getCampaignPrompt(publicCampaign: string): Observable<any> {
    console.log("publicCampaign ", publicCampaign);
    const url = `${this.apiBaseUri}/settings/campaign/prompt/${publicCampaign}`;
    return this.http.get<any>(url, { withCredentials: true }).pipe(
      map((response) => response?.data || {}),
      catchError(this.handleError)
    );
  }

  // Update campaign prompt data
  updateCampaignPrompt(
    publicCampaign: string,
    promptData: any
  ): Observable<any> {
    const url = `${this.apiBaseUri}/settings/campaign/prompt/${publicCampaign}`;
    return this.http
      .post<any>(url, { prompt: promptData }, { withCredentials: true })
      .pipe(catchError(this.handleError));
  }

  loadUserPreferences(userId: number, preferenceKey: string): Observable<any> {
    console.log("loaduserpref ", preferenceKey);
    const url = `${this.apiUserBaseUri}/preferences/${userId}?preferenceKey=${preferenceKey}`;
    return this.http.get<any>(url, { withCredentials: true }).pipe(
      map((response) => {
        console.log("Raw response from backend:", response);
        // Return the response as is, without defaulting to {}
        return response !== undefined && response !== null ? response : {};
      }),
      catchError(this.handleError)
    );
  }

  saveUserPreferences(
    userId: number,
    preferenceKey: string,
    preferenceValue: any
  ): Observable<any> {
    const url = `${this.apiUserBaseUri}/preferences/${userId}`;
    return this.http
      .post<any>(
        url,
        {
          preferenceKey,
          preferenceValue,
        },
        { withCredentials: true }
      )
      .pipe(catchError(this.handleError));
  }

  // Save user favorites
  saveFavourites(userId: number, favourites: number[]): Observable<any> {
    const url = `${this.apiUserBaseUri}/favourites/${userId}`;
    return this.http
      .post<any>(url, { favourites }, { withCredentials: true })
      .pipe(catchError(this.handleError));
  }

  // Fetch user favorites
  getFavourites(userId: number): Observable<number[]> {
    const url = `${this.apiUserBaseUri}/favourites/${userId}`;
    return this.http
      .get<number[]>(url, { withCredentials: true })
      .pipe(catchError(this.handleError));
  }

  // Fetch planner schedule
  getPlannerSchedule(publicCampaign: string): Observable<any> {
    const url = `${this.apiBaseUri}/settings/campaign/schedule/planner/${publicCampaign}`;
    return this.http.get<any>(url, { withCredentials: true }).pipe(
      map((response) => response?.data || []),
      catchError(this.handleError)
    );
  }

  // Fetch dialer schedule
  getDialerSchedule(publicCampaign: string): Observable<any> {
    const url = `${this.apiBaseUri}/settings/campaign/schedule/dialer/${publicCampaign}`;
    return this.http.get<any>(url, { withCredentials: true }).pipe(
      map((response) => response?.data || []),
      catchError(this.handleError)
    );
  }

  private handleError(error: any) {
    console.error("An error occurred:", error);
    return throwError("Something bad happened; please try again later.");
  }
}
