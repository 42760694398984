import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable, Observer } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket: Socket;
  private socketUri = `${environment.socketUri}`;

  constructor() {
    this.connectSocket();
  }

  /**
   * Connect to the WebSocket server and set up event listeners.
   */
  private connectSocket(): void {
    this.socket = io(this.socketUri, {
      path: '/api/socket.io',
      withCredentials: true,
      transports: ['websocket'], // Enforce WebSocket protocol
    });

    this.socket.on('connect', () => {
      console.log(`WebSocket connected with ID: ${this.socket.id}`);
    });

    this.socket.on('disconnect', (reason: string) => {
      console.warn(`WebSocket disconnected: ${reason}`);
    });

    this.socket.on('connect_error', (error: any) => {
      console.error(`WebSocket connection error: ${error.message}`);
    });

    this.socket.on('reconnect_attempt', (attemptNumber: number) => {
      console.info(`WebSocket reconnect attempt #${attemptNumber}`);
    });
  }

  /**
   * Retrieve the current WebSocket ID.
   * @returns The current socket ID, or an empty string if not connected.
   */
  getSocketId(): string {
    return this.socket?.id || ''; // Return the socket ID if available, otherwise an empty string
  }

  /**
   * Join a specific room for progress tracking.
   * @param room The name of the room to join (e.g., uploadId or socketId).
   */
  joinRoom(room: string): void {
    console.log(`Joining WebSocket room: ${room}`);
    this.socket.emit('join', room);
  }

  /**
   * Leave a specific room (optional cleanup).
   * @param room The name of the room to leave.
   */
  leaveRoom(room: string): void {
    console.log(`Leaving WebSocket room: ${room}`);
    this.socket.emit('leave', room);
  }

  /**
   * Listen for import progress updates.
   * @returns An observable that emits progress updates.
   */
  getImportProgress(): Observable<{
    processed: number;
    total: number;
    metadata?: any;
    estimatedTimeRemaining?: number;
  }> {
    return new Observable(
      (
        observer: Observer<{
          processed: number;
          total: number;
          metadata?: any;
          estimatedTimeRemaining?: number;
        }>
      ) => {
        const onProgress = (data: {
          uploadId: string;
          processed: number;
          total: number;
          metadata?: any;
          estimatedTimeRemaining?: number;
        }) => {
          console.log(
            'Progress update received:',
            data.processed,
            'of',
            data.total
          );
          observer.next(data);
        };

        const onComplete = (data: {
          uploadId: string;
          message: string;
          metadata?: any;
        }) => {
          console.log('Upload complete:', data.message, 'Metadata:', data.metadata);
          observer.complete();
        };

        const onError = (data: { uploadId: string; message: string }) => {
          console.error('Upload error:', data.message);
          observer.error(data.message);
        };

        this.socket.on('progress', onProgress);
        this.socket.on('uploadComplete', onComplete);
        this.socket.on('uploadError', onError);

        // Cleanup listeners when the observable is unsubscribed
        return () => {
          console.log('Cleaning up WebSocket progress listeners');
          this.socket.off('progress', onProgress);
          this.socket.off('uploadComplete', onComplete);
          this.socket.off('uploadError', onError);
        };
      }
    );
  }

  /**
   * Emit an event to start tracking progress for an upload.
   * @param uploadId The unique identifier for the upload.
   */
  emitStartUpload(uploadId: string): void {
    console.log('Notifying backend to start tracking progress for Upload ID:', uploadId);
    this.socket.emit('startUpload', { uploadId });
  }

  /**
   * Emit a custom event to the server.
   * @param data The data to send with the custom event.
   */
  sendCustomEvent(data: any): void {
    this.socket.emit('customEvent', data);
  }

  /**
   * Manually connect the WebSocket if not already connected.
   */
  public connect(): void {
    if (!this.socket.connected) {
      this.socket.connect();
      console.log('Manually connecting WebSocket...');
    }
  }

  /**
   * Manually disconnect the WebSocket if connected.
   */
  public disconnect(): void {
    if (this.socket.connected) {
      this.socket.disconnect();
      console.log('Manually disconnecting WebSocket...');
    }
  }
}
