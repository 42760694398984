import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { CampaignsService, Campaign } from 'src/app/_core/_services/campaigns/campaigns.service';

@Injectable({ providedIn: 'root' })
export class CampaignsResolver implements Resolve<Campaign[]> {
  constructor(private campaignsService: CampaignsService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Campaign[]> {
    const workspaceId = route.params['workspaceId'];
    const userId = localStorage.getItem('userId'); // Replace this with your actual logic for getting the user ID
    if (workspaceId) {
      return this.campaignsService.getCampaignsByWorkspaceId(workspaceId).pipe(map((res) => res.data || []));
    } else if (userId) {
      return this.campaignsService.getCampaigns(userId).pipe(map((res) => res.data || []));
    } else {
      return of([]); // Return an empty array if no userId or workspaceId
    }
  }
}
